body {
    font-family: sans-serif;
  }
  
  .react-pdf__Document {
    display: inline-block;
  }
  
  .react-pdf__Page {
    width: 300px;
    height: 424px;
  }
  